import React from "react"
// import Layout from "../components/layout";
import SEO from "../components/seo";
import { graphql } from "gatsby"
import Img from "gatsby-image"
import PageBanner from "../images/blerdcon2021-blerdimension.png";

export default function PageTemplate({
                                     data, // this prop will be injected by the GraphQL query below.
                                 }) {
    const { markdownRemark } = data // data.markdownRemark holds your post data

    return (
        <>
            <SEO
                title={markdownRemark.frontmatter.title}
                description="Blerdimension will be hosting two panels during Blerdcon 2021"
                bannerImg={PageBanner}
            />
            <header className="masthead" style={{backgroundImage: `url(${PageBanner})`}}>
                <div  className="container">
                    {/*<div className="masthead-subheading">Welcome To the blerdimension!</div>*/}
                    {/*<div className="masthead-heading text-uppercase">It's Nice To Meet You</div>*/}
                    {/*<a className="btn btn-primary btn-xl text-uppercase" href="#services">Tell Me More</a>*/}
                </div>
            </header>
            <div className="container">
                <div style={{marginTop: '20px'}} className="row">
                    <div className="col-sm-12">
                        <h1 className="text-center">{markdownRemark.frontmatter.title}</h1>
                        {/* TODO: Add row here and place container for banner */}
                        {/*<img src={} alt="page banner"/>*/}
                        <div
                            dangerouslySetInnerHTML={{ __html: markdownRemark.html }}
                        />
                    </div>
                </div>
                <section className="page-section" id="social-row">
                    <div className="container">
                        <div className="text-center">
                            <h2 className="section-heading text-uppercase">Subscribe and Follow</h2>
                            <h3 className="section-subheading text-muted">Join the Blerdimension Community</h3>
                        </div>
                        <div className="row text-center justify-content-center">
                            <div className="col-sm-6 col-md-2">
                                <a href="https://anchor.fm/blerdimension" className="btn btn-dark btn-social"><i className="fas fa-microphone"></i></a>
                                <h4 className="my-3">Podcast</h4>
                            </div>
                            <div className="col-sm-6 col-md-2">
                                <a href="https://www.youtube.com/channel/UCsSy_UKX7zffw6oB2JoOB4w" className="btn btn-dark btn-social"><i className="fab fa-youtube"></i></a>
                                <h4 className="my-3">Youtube</h4>
                            </div>
                            <div className="col-sm-6 col-md-2">
                                <a href="https://www.twitch.tv/blerdimension" className="btn btn-dark btn-social"><i className="fab fa-twitch"></i></a>
                                <h4 className="my-3">Twitch</h4>
                                {/*<p className="text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Minima*/}
                                {/*    maxime quam architecto quo inventore harum ex magni, dicta impedit.</p>*/}
                            </div>
                            <div className="col-sm-6 col-md-2">
                                <a href="https://www.instagram.com/blerdimension" className="btn btn-dark btn-social"><i className="fab fa-instagram"></i></a>
                                <h4 className="my-3">Instagram</h4>
                            </div>
                            <div className="col-sm-6 col-md-2">
                                <a href="https://discord.gg/gc57q7V" className="btn btn-dark btn-social"><i className="fab fa-discord"></i></a>
                                <h4 className="my-3">Discord</h4>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

        </>
    )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: {path: {eq: $path}}) {
        frontmatter {
          title
          banner {
            childImageSharp {
              fluid(maxWidth: 1000){
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        html
      }
  }
`